import boxShadows from 'assets/theme/base/boxShadows';
import typography from 'assets/theme/base/typography';
import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import linearGradient from 'assets/theme/functions/linearGradient';

import pxToRem from 'assets/theme/functions/pxToRem';

const { borderWidth } = borders;
const { lg } = boxShadows;
const { size } = typography;
const { white, borderCol, gradients, transparent } = colors;
const { borderRadius } = borders;

const menu = {
  defaultProps: {
    disableAutoFocusItem: true,
  },

  styleOverrides: {
    '& .MuiIcon-root': {
      stroke: white.main,
    },
    paper: {
      minWidth: pxToRem(160),
      boxShadow: lg,
      padding: `0 !important`,
      fontSize: size.sm,
      color: white.main,
      textAlign: 'left',
      border: `${borderWidth[1]} solid ${borderCol.navbar}`,
      borderRadius: borderRadius.md,
    },
    list: {
      background: linearGradient(
        gradients.menu.main,
        gradients.menu.state,
        gradients.menu.deg
      ),
      '& .MuiMenuItem-root': {
        '& .MuiBox-root .MuiTypography-root': {
          color: white.main,
        },
        '&:hover': {
          background: transparent.main,
        },
      },
    },
  },
};
export default menu;
