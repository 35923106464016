import React, { forwardRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';
import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';
import { formatDistanceToNow } from 'date-fns';
import { useNotifications } from 'context/NotificationContext';

const NotificationItem = ({ color, image, notification, ...rest }) => {
  const { title, content, isRead, createdAt, attachedLink } = notification;
  const { markAsRead } = useNotifications();
  const handleClick = async () => {
    if (!isRead) {
      await markAsRead(notification?.id);
    }
    if (attachedLink) {
      window.open(attachedLink, '_blank');
    }
  };

  return (
    <MenuItem
      {...rest}
      sx={{
        background: !isRead
          ? 'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%) !important'
          : 'rgba(15, 18, 59, 0.9) !important',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        position: 'relative',
        '&:hover': {
          backgroundColor: 'rgba(15, 18, 59, 0.5) !important', // Solid hover background color
        },
        // marginBottom: '50px',
      }}
      onClick={handleClick}
    >
      <VuiBox mr={2} borderRadius="lg" mt="2px">
        <Icon fontSize="small" sx={{ color: !isRead ? 'red' : '' }}>
          notifications
        </Icon>
      </VuiBox>
      <VuiBox>
        <VuiTypography
          variant="button"
          textTransform="capitalize"
          fontWeight={isRead ? 'regular' : 'bold'}
        >
          {title}
        </VuiTypography>
        {content && (
          <VuiTypography
            variant="caption"
            color="text"
            sx={{
              display: 'block',
              mt: 0.5,
            }}
          >
            {content}
          </VuiTypography>
        )}
        {/* Notification Date */}
        <VuiTypography
          variant="caption"
          color="text"
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 0.5,
          }}
        >
          <VuiTypography variant="button" color="text">
            <Icon
              sx={{
                lineHeight: 1.2,
                mr: 0.5,
              }}
            >
              watch_later
            </Icon>
          </VuiTypography>
          {formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
        </VuiTypography>

        {!isRead && (
          <span
            style={{
              position: 'absolute',
              width: '8px',
              height: '8px',
              background: 'red',
              right: '10px',
              top: '40%',
              borderRadius: '10px',
            }}
          ></span>
        )}
      </VuiBox>
    </MenuItem>
  );
};

NotificationItem.displayName = 'NotificationItem';

export default NotificationItem;
