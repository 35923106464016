import React, { useState } from 'react';
import FormModal from './FormModal';
import ModalInputField from './ModalInputField';
import ModalColorPicker from './ModalColorPicker';
import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';
import toast from 'react-hot-toast';

const ThemeFormModal = ({ onClose, onSave, themeToEdit }) => {
  const [appColor, setAppColor] = useState(themeToEdit?.appColor || '#ffffff');
  const [appLogoUrl, setAppLogoUrl] = useState(themeToEdit?.appLogoUrl || '');
  const [isActive, setIsActive] = useState(themeToEdit?.isActive || false);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (!appColor) {
      toast.error('App Color is required.');
      return;
    }

    setLoading(true);
    try {
      await onSave({ appColor, appLogoUrl, isActive });
      onClose();
    } catch (error) {
      toast.error('Failed to save theme.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormModal
      title={themeToEdit ? 'Edit Theme' : 'Add New Theme'}
      fields={[
        <ModalColorPicker
          key="appColor"
          label="App Color"
          color={appColor}
          onChange={setAppColor}
        />,
        <ModalInputField
          key="appLogoUrl"
          label="App Logo URL"
          value={appLogoUrl}
          onChange={(e) => setAppLogoUrl(e.target.value)}
          placeholder="Enter logo URL"
          type="url"
        />,
        <VuiBox key="isActive" mb={2} display="flex" alignItems="center">
          <input
            type="checkbox"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
            style={{ marginRight: '8px' }}
          />
          <VuiTypography
            component="span"
            variant="button"
            color="white"
            fontWeight="medium"
          >
            Set as Active
          </VuiTypography>
        </VuiBox>,
      ]}
      onClose={onClose}
      onSubmit={handleSave}
      submitButtonText={loading ? 'Saving...' : 'Save'}
      loading={loading}
    />
  );
};

export default ThemeFormModal;
