import React from 'react';
import VuiTypography from 'components/base/VuiTypography';
import VuiSelect from 'components/base/VuiSelect';
import VuiBox from 'components/base/VuiBox';

const ModalSelectField = ({ label, value, onChange, options }) => (
  <VuiBox mb={4}>
    <VuiTypography
      component="label"
      variant="button"
      color="white"
      fontWeight="medium"
    >
      {label}
    </VuiTypography>

    <VuiSelect
      value={value}
      onChange={(selectedValue) => onChange(selectedValue)}
      options={options}
      placeholder={`Select ${label}`}
    />
  </VuiBox>
);

export default ModalSelectField;
