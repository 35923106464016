import React from 'react';
import ModalWrapper from './ModalWrapper';
import VuiButton from 'components/base/VuiButton';
import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';

const FormModal = ({
  title,
  fields,
  onClose,
  onSubmit,
  submitButtonText,
  loading,
}) => (
  <ModalWrapper onClose={onClose}>
    <VuiTypography variant="h5" fontWeight="bold" mb={2} color="white">
      {title}
    </VuiTypography>
    {fields.map((field, index) => (
      <div key={index}>{field}</div>
    ))}
    <VuiBox display="flex" justifyContent="space-between" mt={3}>
      <VuiButton color="secondary" onClick={onClose} disabled={loading}>
        Cancel
      </VuiButton>
      <VuiButton color="info" onClick={onSubmit} disabled={loading}>
        {loading ? 'Saving...' : submitButtonText}
      </VuiButton>
    </VuiBox>
  </ModalWrapper>
);

export default FormModal;
