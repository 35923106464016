import React from 'react';
import VuiBox from 'components/base/VuiBox';

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99999,
  },
  modalContainer: {
    maxWidth: '400px',
    width: '100%',
    background:
      'linear-gradient(159.02deg, rgb(15, 18, 59) 14.25%, rgb(9, 13, 46) 56.45%, rgb(2, 5, 21) 86.14%)',
    borderRadius: '8px',
    padding: '24px',
  },
};

const ModalWrapper = ({ children, onClose }) => (
  <div style={modalStyles.overlay}>
    <div style={modalStyles.modalContainer}>
      <VuiBox>{children}</VuiBox>
    </div>
  </div>
);

export default ModalWrapper;
