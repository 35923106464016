import { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import OrganizationFormModal from '../components/Modals/OrganizationFormModal';

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [showOrgModal, setShowOrgModal] = useState(false);

  const login = async (credentials) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/auth/signin', credentials);
      toast.success('Login successful!');
      setIsAuthenticated(true);
      localStorage.setItem('token', response.data.access_token);
      getProfile();

      // Redirect to dashboard after login
      if (response?.data?.userRole === 'super-admin') {
        history.push('/notification');
      } else {
        history.push('/profile');
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || 'Login failed. Unauthorized.'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getProfile();
    } else {
      setProfileLoading(false); // Ensure loading is false if no token exists
    }
  }, []);

  const getProfile = async () => {
    setProfileLoading(true);
    try {
      const response = await axiosInstance.get('/auth/profile');
      setUser(response.data);
      setIsAuthenticated(true);
      if (
        !response.data.organizationId &&
        response.data.userRole === 'org-admin'
      ) {
      }
    } catch (error) {
      toast.error('Failed to load profile');
      setIsAuthenticated(false);
      setUser(null);
      localStorage.removeItem('token');
    } finally {
      setProfileLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
    toast.success('Logged out successfully!');
    history.push('/authentication/sign-in');
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        isAuthenticated,
        setIsAuthenticated,
        user,
        login,
        logout,
        profileLoading,
        getProfile,
        setShowOrgModal,
      }}
    >
      {children}

      {showOrgModal && (
        <OrganizationFormModal
          onClose={() => setShowOrgModal(false)}
          onCreate={() => {
            setShowOrgModal(false);
            getProfile();
          }}
          org={user?.organization}
        />
      )}
    </AuthContext.Provider>
  );
};

// Hook to use AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
