import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, profileLoading } = useAuth();

  if (profileLoading) return <div>Loading...</div>;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/authentication/sign-in" />
        )
      }
    />
  );
};

export default PrivateRoute;
