import React, { useState, useEffect, useCallback } from 'react';
import {
  HiOutlinePencilSquare,
  HiOutlineTrash,
  HiOutlineCheckCircle,
} from 'react-icons/hi2';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import toast from 'react-hot-toast';

import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';
import VuiButton from 'components/base/VuiButton';
import Table from 'components/ui/Tables/Table';
import DashboardLayout from 'components/layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/layout/Navbars/DashboardNavbar';
import Footer from 'components/layout/Footer';
import { useAuth } from 'context/AuthContext';
import { useProfileTheme } from 'context/ProfileThemeContext';
import ThemeFormModal from 'components/Modals/ThemeFormModal';
import { ConfirmModal } from 'components/Modals/ConfirmModal';

function ProfileTheme() {
  const { user } = useAuth();
  const {
    themes,
    loading,
    fetchThemes,
    createTheme,
    updateTheme,
    deleteTheme,
  } = useProfileTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editThemeId, setEditThemeId] = useState(null);
  const [themeToEdit, setThemeToEdit] = useState(null);
  const [deleteModalProps, setDeleteModalProps] = useState(undefined);

  useEffect(() => {
    if (user?.organizationId) {
      fetchThemes(user.organizationId);
    }
  }, [user?.organizationId, fetchThemes]);

  const columns = [
    { name: 'id', align: 'center', label: 'ID' },
    { name: 'appColor', align: 'center', label: 'App Color' },
    { name: 'appLogoUrl', align: 'center', label: 'Logo URL' },
    { name: 'isActive', align: 'center', label: 'Active' },
    { name: 'actions', align: 'center', label: 'Actions' },
  ];

  const rows = themes
    ?.slice()
    .sort((a, b) => a.id - b.id)
    .map((theme) => ({
      id: (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          {theme.id}
        </VuiTypography>
      ),
      appColor: (
        <VuiBox display="flex" justifyContent="center">
          <VuiTypography variant="caption" color="white" fontWeight="medium">
            {theme.appColor}
          </VuiTypography>
        </VuiBox>
      ),
      appLogoUrl: (
        <VuiTypography variant="caption" color="text" fontWeight="medium">
          {theme.appLogoUrl || 'N/A'}
        </VuiTypography>
      ),
      isActive: (
        <VuiBox
          display="flex"
          justifyContent="center"
          color={theme.isActive ? 'success' : 'info'}
        >
          {theme.isActive ? (
            <VuiTypography
              variant="caption"
              color="success"
              fontWeight="medium"
            >
              Active
            </VuiTypography>
          ) : (
            <IconButton
              disabled={theme.status === 'disabled'}
              onClick={() => handleActivate(theme.id)}
            >
              <HiOutlineCheckCircle color="white" size={16} />
            </IconButton>
          )}
        </VuiBox>
      ),
      actions: (
        <VuiBox display="flex" justifyContent="center" gap={1}>
          {user.userRole === 'org-admin' && (
            <>
              {theme.status === 'active' && (
                <IconButton onClick={() => handleEdit(theme)}>
                  <HiOutlinePencilSquare color="white" size={16} />
                </IconButton>
              )}

              <IconButton onClick={() => handleDelete(theme.id)}>
                <HiOutlineTrash color="white" size={16} />
              </IconButton>
            </>
          )}
        </VuiBox>
      ),
      status: theme.status,
    }));

  const handleActivate = async (themeId) => {
    if (user?.organizationId) {
      await updateTheme(user.organizationId, themeId, { isActive: true });
      toast.success('Theme activated successfully!');
    }
  };

  const handleEdit = (theme) => {
    setThemeToEdit(theme);
    setEditThemeId(theme.id);
    setIsModalOpen(true);
  };

  const handleDelete = useCallback(
    (themeId) => {
      if (!user?.organizationId) {
        return;
      }

      setDeleteModalProps({
        onConfirm: () => {
          deleteTheme(user.organizationId, themeId);
        },
        title: 'Delete Podio Theme',
        prompt: `Are you sure you want to delete this theme?`,
        importance: 'error',
      });
    },
    [deleteTheme, user.organizationId]
  );

  const handleSave = async (themeData) => {
    if (user?.organizationId) {
      if (editThemeId) {
        await updateTheme(user.organizationId, editThemeId, themeData);
      } else {
        await createTheme(user.organizationId, themeData);
      }
      setIsModalOpen(false);
      setEditThemeId(null);
      setThemeToEdit(null);
    }
  };

  const isUserLimitReached =
    user.profileThemeLimit !== -1 && themes?.length >= user.profileThemeLimit;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox
        py={3}
        sx={{
          flex: '1',
        }}
      >
        <VuiBox mb={3}>
          <Card>
            <VuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="22px"
              p={3}
            >
              <VuiTypography variant="lg" color="white">
                Profile Themes
              </VuiTypography>
              {(user?.userRole === 'super-admin' ||
                (user?.userRole === 'org-admin' && !isUserLimitReached)) && (
                <VuiButton color="info" onClick={() => setIsModalOpen(true)}>
                  Add Theme
                </VuiButton>
              )}
            </VuiBox>
            {themes.length > 0 ? (
              <VuiBox
                sx={{
                  '& th': {
                    borderBottom: ({
                      borders: { borderWidth },
                      palette: { grey },
                    }) => `${borderWidth[1]} solid ${grey[700]}`,
                  },
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({
                        borders: { borderWidth },
                        palette: { grey },
                      }) => `${borderWidth[1]} solid ${grey[700]}`,
                    },
                  },
                }}
              >
                <Table
                  columns={columns}
                  rows={rows}
                  loading={loading}
                  rowStyle={(rowData) => ({
                    backgroundColor:
                      rowData.themeStatus === 'disabled'
                        ? 'grey.800'
                        : 'inherit',
                  })}
                />
              </VuiBox>
            ) : (
              <VuiBox p={3} display="flex" justifyContent="center">
                <VuiTypography variant="h6" color="text">
                  No themes available. Please add a new theme.
                </VuiTypography>
              </VuiBox>
            )}
          </Card>
        </VuiBox>
        {isModalOpen && (
          <ThemeFormModal
            onClose={() => {
              setIsModalOpen(false);
              setEditThemeId(null);
              setThemeToEdit(null);
            }}
            onSave={handleSave}
            themeToEdit={themeToEdit}
          />
        )}
        {deleteModalProps != null && (
          <ConfirmModal
            onClose={() => {
              setDeleteModalProps(undefined);
            }}
            onConfirm={deleteModalProps.onConfirm}
            title={deleteModalProps.title}
            prompt={deleteModalProps.prompt}
            importance={deleteModalProps.importance}
          />
        )}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProfileTheme;
