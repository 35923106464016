import Grid from '@mui/material/Grid';
import VuiBox from 'components/base/VuiBox';
import Footer from 'components/layout/Footer';
import DashboardLayout from 'components/layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/layout/Navbars/DashboardNavbar';
import ProfileDetailsCard from './components/ProfileDetailsCard';

function Overview() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox
        py={3}
        sx={{
          flex: '1',
        }}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <ProfileDetailsCard />
          </Grid>
        </Grid>
      </VuiBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
