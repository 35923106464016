import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';

function Footer() {
  return (
    <VuiBox
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row' }}
      justifyContent="flex-end"
      direction="row"
      component="footer"
    >
      <VuiBox item xs={12} sx={{ textAlign: 'center' }}>
        <VuiTypography
          variant="button"
          sx={{ textAlign: 'center', fontWeight: '400 !important' }}
          color="white"
        >
          @ 2024, Made with ❤️&nbsp;&nbsp;&nbsp; by{' '}
          <VuiTypography
            component="span"
            variant="button"
            sx={{ textAlign: 'center', fontWeight: '500 !important' }}
            color="white"
            mr="2px"
          >
            Podio Team
          </VuiTypography>
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}

export default Footer;
