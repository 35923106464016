import React from 'react';
import { Grid } from '@mui/material';
import DashboardLayout from 'components/layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/layout/Navbars/DashboardNavbar';
import Footer from 'components/layout/Footer';
import VuiBox from 'components/base/VuiBox';
import NotificationForm from './components/NotificationForm';
import { useAuth } from 'context/AuthContext';

function Notification() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3} minHeight={'90vh'}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item xs={12} md={6} lg={6} sx={{ display: 'flex' }}>
            <NotificationForm />
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Notification;
