import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

const VuiSelect = ({
  value,
  onChange,
  options,
  multiple = false,
  menuPortalTarget,
  ...rest
}) => {
  // Custom styles for React-Select
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: 'rgb(15, 18, 59)',
      color: 'white',
      border: '0.5px solid #4a5568',
      borderRadius: '14px',
      height: '40px',
      boxShadow: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: '0',
      '&:hover': {
        borderColor: '#007bff',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
    }),
    input: (base) => ({
      ...base,
      color: 'white',
      fontSize: '14px',
      margin: '0',
      padding: '0',
    }),
    placeholder: (base) => ({
      ...base,
      color: 'rgba(32,43,75,255)',
      fontSize: '14px',
      lineHeight: '1',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'white',
      lineHeight: '1',
      fontSize: '14px',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? '#007bff'
        : state.isFocused
          ? 'rgba(0, 123, 255, 0.1)'
          : 'rgb(15, 18, 59)',
      color: 'white',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: 'rgba(0, 123, 255, 0.2)',
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: 'rgb(15, 18, 59)',
      zIndex: 999999,
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: '#007bff',
      color: 'white',
      fontSize: '14px',
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: 'white',
      fontSize: '14px',
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: 'white',
      ':hover': {
        backgroundColor: '#0056b3',
        color: 'white',
      },
    }),
  };

  return (
    <>
      <ReactSelect
        value={
          multiple
            ? options?.filter((option) => value.includes(option.value))
            : options.find((option) => option.value === value)
        }
        onChange={(selected) => {
          if (multiple) {
            onChange(selected ? selected.map((opt) => opt.value) : []);
          } else {
            onChange(selected ? selected.value : null);
          }
        }}
        options={options}
        isMulti={multiple}
        styles={customStyles}
        menuPortalTarget={menuPortalTarget ? document.body : null}
        {...rest}
      />
    </>
  );
};

export default VuiSelect;
