import React, { useState } from 'react';
import FormModal from './FormModal';
import ModalInputField from './ModalInputField';
import toast from 'react-hot-toast';

const InviteUserModal = ({ onClose, onInvite }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInvite = async () => {
    if (!email) {
      toast.error('Email is required.');
      return;
    }

    setLoading(true);
    try {
      await onInvite({ email });
      onClose();
    } catch (error) {
      toast.error('Failed to send invitation.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormModal
      title="Invite User"
      fields={[
        <ModalInputField
          key="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter user email"
          type="email"
        />,
      ]}
      onClose={onClose}
      onSubmit={handleInvite}
      submitButtonText={loading ? 'Inviting...' : 'Send Invite'}
      loading={loading}
    />
  );
};

export default InviteUserModal;
