import React, { useState, useEffect } from 'react';
import FormModal from './FormModal';
import ModalInputField from './ModalInputField';
import ModalSelectField from './ModalSelectField';
import toast from 'react-hot-toast';

const UserFormModal = ({ onClose, onSave, userToEdit }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userRole, setUserRole] = useState('normal');
  const [userStatus, setUserStatus] = useState('active');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userToEdit) {
      setName(userToEdit?.name);
      setEmail(userToEdit?.email);
      setUserRole(userToEdit?.userRole);
      setUserStatus(userToEdit?.status);
    } else {
      setName('');
      setEmail('');
      setPassword('');
      setUserRole('normal');
      setUserStatus('active');
    }
  }, [userToEdit]);

  const handleSave = async () => {
    if (!name || !email || (!userToEdit && !password)) {
      toast.error('All fields are required.');
      return;
    }

    setLoading(true);
    try {
      await onSave({ name, email, password, userRole });
      onClose();
    } catch (error) {
      toast.error('Failed to save user.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormModal
      title={userToEdit ? 'Edit User' : 'Add New User'}
      fields={[
        <ModalInputField
          key="name"
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter name"
        />,
        <ModalInputField
          key="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email"
          type="email"
        />,
        !userToEdit && (
          <ModalInputField
            key="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            type="password"
          />
        ),
        <ModalSelectField
          key="userRole"
          label="Status"
          value={userStatus}
          onChange={(selectedStatus) => setUserStatus(selectedStatus)}
          options={[
            { value: 'active', label: 'Active' },
            { value: 'disabled', label: 'Disabled' },
          ]}
        />,
        <ModalSelectField
          key="userRole"
          label="Role"
          value={userRole}
          onChange={(selectedRole) => setUserRole(selectedRole)} // Updates the role as an object
          options={[
            { value: 'normal', label: 'Normal' },
            { value: 'org-admin', label: 'Admin' },
          ]}
        />,
      ]}
      onClose={onClose}
      onSubmit={handleSave}
      submitButtonText={loading ? 'Saving...' : 'Save'}
      loading={loading}
    />
  );
};

export default UserFormModal;
