import React from 'react';
import { Card, Grid, Box, List, ListItem, ListItemIcon } from '@mui/material';
import VuiTypography from 'components/base/VuiTypography';
import VuiButton from 'components/base/VuiButton';

function PlanCard({ plan, isActive, actionType, onAction, loading }) {
  const renderActionButton = () => {
    switch (actionType) {
      case 'current':
        return (
          <VuiButton size="large" color="info" disabled>
            Current Plan
          </VuiButton>
        );
      case 'upgrade':
        return (
          <VuiButton
            size="large"
            color="success"
            fullWidth
            onClick={() => onAction(plan.tier)}
            disabled={loading}
          >
            {loading ? 'Processing...' : `Upgrade to ${plan.title}`}
          </VuiButton>
        );
      case 'downgrade':
        return (
          <VuiButton
            size="large"
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => onAction(plan.tier)}
            disabled={loading}
          >
            {loading ? 'Processing...' : `Downgrade to ${plan.title}`}
          </VuiButton>
        );
      default:
        return null;
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        sx={{
          padding: 2,
          border: isActive ? '2px solid #1976d2' : '1px solid #ccc',
          background: 'transparent',
          boxShadow: isActive ? '0 4px 10px rgba(0, 0, 0, 0.2)' : 'none',
        }}
      >
        <VuiTypography
          variant="h4"
          fontWeight="bold"
          color="white"
          mb={2}
          align="center"
        >
          {plan.title}
        </VuiTypography>

        <VuiTypography
          variant="subtitle2"
          fontWeight="medium"
          color="white"
          mb={2}
          align="center"
        >
          <span style={{ textDecoration: 'line-through', marginRight: '4px' }}>
            {plan.price}
          </span>
          for FREE
        </VuiTypography>
        <Box sx={{ marginBottom: 2 }}>
          <VuiTypography
            variant="subtitle1"
            fontWeight="medium"
            gutterBottom
            color="white"
          >
            Whats included:
          </VuiTypography>
          <List
            sx={{
              height: '100px',
            }}
          >
            {plan.features.map((feature, index) => (
              <ListItem
                key={index}
                sx={{ display: 'flex', alignItems: 'flex-start', padding: 0 }}
              >
                <VuiTypography
                  variant="h6"
                  fontWeight={400}
                  color="white"
                  fontSize={12}
                  lineHeight={1}
                  marginBottom="10px"
                >
                  - {feature}
                </VuiTypography>
              </ListItem>
            ))}
          </List>
        </Box>
        {renderActionButton()}
      </Card>
    </Grid>
  );
}

export default PlanCard;
