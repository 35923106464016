import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import axiosInstance from '../api/axiosInstance';
import toast from 'react-hot-toast';
import { useAuth } from './AuthContext';

const ProfileThemeContext = createContext(undefined);

export const ProfileThemeProvider = ({ children }) => {
  const [themes, setThemes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTheme, setActiveTheme] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    if (user?.organizationId) {
      fetchActiveTheme(user.organizationId);
    }
  }, [user?.organizationId]);

  const fetchActiveTheme = async (organizationId) => {
    if (!organizationId) return;
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/organizations/${organizationId}/themes/active`
      );
      setActiveTheme(response.data);
    } catch (error) {
      toast.error('Failed to fetch active theme');
    } finally {
      setLoading(false);
    }
  };

  const fetchThemes = useCallback(async (orgId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/organizations/${orgId}/themes`
      );
      setThemes(response.data);
      const active = response.data.find((theme) => theme.isActive);
      setActiveTheme(active || null);
    } catch (error) {
      toast.error('Failed to fetch themes');
    } finally {
      setLoading(false);
    }
  }, []);

  const createTheme = async (orgId, themeData) => {
    setLoading(true);
    try {
      await axiosInstance.post(`/organizations/${orgId}/themes`, themeData);
      toast.success('Theme created successfully');
      fetchThemes(orgId);
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Failed to create theme');
    } finally {
      setLoading(false);
    }
  };

  const updateTheme = async (orgId, themeId, themeData) => {
    setLoading(true);
    try {
      await axiosInstance.patch(
        `/organizations/${orgId}/themes/${themeId}`,
        themeData
      );
      toast.success('Theme updated successfully');
      fetchThemes(orgId); // Refresh themes after update
    } catch (error) {
      toast.error('Failed to update theme');
    } finally {
      setLoading(false);
    }
  };

  const deleteTheme = async (orgId, themeId) => {
    setLoading(true);
    try {
      await axiosInstance.delete(`/organizations/${orgId}/themes/${themeId}`);
      toast.success('Theme deleted successfully');
      fetchThemes(orgId); // Refresh themes after deletion
    } catch (error) {
      if (error.response?.data?.message) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error('Failed to delete theme');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProfileThemeContext.Provider
      value={{
        themes,
        loading,
        fetchThemes,
        createTheme,
        updateTheme,
        deleteTheme,
        activeTheme,
      }}
    >
      {children}
    </ProfileThemeContext.Provider>
  );
};

export const useProfileTheme = () => {
  const context = useContext(ProfileThemeContext);
  if (!context) {
    throw new Error(
      'useProfileTheme must be used within a ProfileThemeProvider'
    );
  }
  return context;
};
