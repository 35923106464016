import React, { useState } from 'react';
import toast from 'react-hot-toast';
import FormModal from './FormModal';
import ModalInputField from './ModalInputField';
import axiosInstance from 'api/axiosInstance';

const OrganizationFormModal = ({ onClose, onCreate, org }) => {
  const [orgName, setOrgName] = useState(org?.name || '');
  const [address, setAddress] = useState(org?.address || '');
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (!orgName || !address) {
      toast.error('Organization name and address are required.');
      return;
    }

    setLoading(true);
    try {
      await axiosInstance.patch(`/organizations/${org?.id}`, {
        name: orgName,
        address: address,
      });
      toast.success('Organization updated successfully!');
      onCreate();
    } catch (error) {
      toast.error(
        error.response?.data?.message || 'Failed to updated organization'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormModal
      title="Update Organization"
      fields={[
        <ModalInputField
          key="name"
          label="Organization Name"
          value={orgName}
          onChange={(e) => setOrgName(e.target.value)}
          placeholder="Enter organization name"
        />,
        <ModalInputField
          key="address"
          label="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Enter address"
        />,
      ]}
      onClose={onClose}
      onSubmit={handleSave}
      submitButtonText="Update Organization"
      loading={loading}
    />
  );
};

export default OrganizationFormModal;
