import React, { useCallback } from 'react';
import ModalWrapper from './ModalWrapper';
import VuiButton from 'components/base/VuiButton';
import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';

export const ConfirmModal = ({
  title,
  prompt,
  onClose,
  onConfirm,
  importance,
  loading,
}) => {
  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onClose, onConfirm]);

  return (
    <ModalWrapper onClose={onClose}>
      <VuiTypography variant="h5" fontWeight="bold" mb={2} color="white">
        {title}
      </VuiTypography>
      <VuiTypography mb={2} color="white">
        {prompt}
      </VuiTypography>

      <VuiBox display="flex" justifyContent="space-between" mt={3}>
        <VuiButton color="secondary" onClick={onClose} disabled={loading}>
          Cancel
        </VuiButton>
        <VuiButton
          color={importance ?? 'primary'}
          onClick={handleConfirm}
          disabled={loading}
        >
          Confirm
        </VuiButton>
      </VuiBox>
    </ModalWrapper>
  );
};
