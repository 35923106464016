import React from 'react';
import { useHistory } from 'react-router-dom';
import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';
import { Button } from '@mui/material';
import PageLayout from 'components/layout/LayoutContainers/PageLayout';

function NotFound() {
  const history = useHistory();

  const handleBackToDashboard = () => {
    history.push('/profile');
  };

  return (
    <PageLayout>
      <VuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        py={3}
        minHeight="90vh"
      >
        <VuiTypography
          variant="h5"
          fontWeight="bold"
          color="white"
          mb={2}
          textAlign="center"
        >
          Not Found
        </VuiTypography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBackToDashboard}
          sx={{ mt: 2 }}
        >
          Back to HomePage
        </Button>
      </VuiBox>
    </PageLayout>
  );
}

export default NotFound;
