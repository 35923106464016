import { useEffect } from 'react';

import { useLocation, NavLink } from 'react-router-dom';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';

import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';
import VuiButton from 'components/base/VuiButton';

import SidenavCollapse from 'components/layout/Sidenav/SidenavCollapse';

import SidenavRoot from 'components/layout/Sidenav/SidenavRoot';
import sidenavLogoLabel from 'components/layout/Sidenav/styles/sidenav';

import {
  useVisionUIController,
  setMiniSidenav,
  setTransparentSidenav,
} from 'context';

import SimmmpleLogo from 'components/ui/Icons/SimmmpleLogo';
import { useAuth } from 'context/AuthContext';

function Sidenav({ color, brandName, routes, ...rest }) {
  const { logout, user } = useAuth();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1440) {
        setTransparentSidenav(dispatch, false);
      }
    };

    // Call handleResize initially to set the state on mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);

  const filteredRoutes = routes.filter(
    ({ key, authRequired, requiredOrgTier }) =>
      key !== 'sign-in' &&
      key !== 'sign-up' &&
      key !== 'finish-registration' &&
      (!authRequired || user) && (user.userRole === 'super-admin' ||
        !requiredOrgTier ||
        user?.organization?.orgTier === requiredOrgTier
      )
  );

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = filteredRoutes.map(
    ({ type, name, icon, title, noCollapse, key, route, href }) => {
      let returnValue;

      if (type === 'collapse') {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: 'none' }}
          >
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink to={route} key={key}>
            <SidenavCollapse
              color={color}
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </NavLink>
        );
      } else if (type === 'title') {
        returnValue = (
          <VuiTypography
            key={key}
            color="white"
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </VuiTypography>
        );
      } else if (type === 'divider') {
        returnValue = <Divider light key={key} />;
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, miniSidenav }}
    >
      <VuiBox
        pt={3.5}
        pb={0.5}
        px={4}
        textAlign="center"
        sx={{
          overflow: 'unset !important',
        }}
      >
        <VuiBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <VuiTypography variant="h6" color="text">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </VuiTypography>
        </VuiBox>
        <VuiBox
          component={NavLink}
          to="/profile"
          display="flex"
          alignItems="center"
        >
          <VuiBox
            sx={
              ((theme) => sidenavLogoLabel(theme, { miniSidenav }),
              {
                display: 'flex',
                alignItems: 'center',
                margin: '0 auto',
              })
            }
          >
            <VuiBox
              display="flex"
              sx={
                ((theme) =>
                  sidenavLogoLabel(theme, { miniSidenav, transparentSidenav }),
                {
                  mr:
                    miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
                })
              }
            >
              <SimmmpleLogo size="24px" />
            </VuiBox>
            <VuiTypography
              variant="button"
              textGradient={true}
              color="logo"
              fontSize={14}
              letterSpacing={2}
              fontWeight="medium"
              sx={
                ((theme) =>
                  sidenavLogoLabel(theme, { miniSidenav, transparentSidenav }),
                {
                  opacity:
                    miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
                  maxWidth:
                    miniSidenav || (miniSidenav && transparentSidenav)
                      ? 0
                      : '100%',
                  margin: '0 auto',
                })
              }
            >
              {brandName}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
      <Divider light />
      <List>{renderRoutes}</List>
      <VuiBox
        my={2}
        mx={2}
        mt="auto"
        sx={({ breakpoints }) => ({
          [breakpoints.up('xl')]: {
            pt: 2,
          },
          [breakpoints.only('xl')]: {
            pt: 1,
          },
          [breakpoints.down('xl')]: {
            pt: 2,
          },
        })}
      >
        <VuiBox mt={2}>
          <VuiButton
            component="button"
            onClick={() => {
              logout();
            }}
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            color={color}
            fullWidth
          >
            Logout
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </SidenavRoot>
  );
}

export default Sidenav;
