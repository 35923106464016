import colors from 'assets/theme/base/colors';
import bgAdmin from 'assets/images/body-background.png';

const { info, dark } = colors;
const globals = {
  html: {
    scrollBehavior: 'smooth',
    background: dark.body,
  },
  body: {
    background: `url(${bgAdmin})`,
    backgroundSize: 'cover',
  },
  '*, *::before, *::after': {
    margin: 0,
    padding: 0,
  },
  'a, a:link, a:visited': {
    textDecoration: 'none !important',
  },
  'a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited': {
    color: `${dark.main} !important`,
    transition: 'color 150ms ease-in !important',
  },
  'a.link:hover, .link:hover, a.link:focus, .link:focus': {
    color: `${info.main} !important`,
  },
};

export default globals;
