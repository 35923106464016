import React, { createContext, useCallback, useContext, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import toast from 'react-hot-toast';

const UserContext = createContext(undefined);

export const UserProvider = ({ children }) => {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchOrgUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/users');
      setUsers(response.data);
    } catch (error) {
      toast.error('Error fetching users');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/users/all');
      setUsers(response.data);
    } catch (error) {
      toast.error('Error fetching users');
    } finally {
      setLoading(false);
    }
  }, []);

  const addUser = async (userData) => {
    setLoading(true);
    try {
      await axiosInstance.post('/users', userData);
      toast.success('User added successfully!');
      fetchUsers(); // Refresh user list
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error adding user');
    } finally {
      setLoading(false);
    }
  };

  const inviteUser = async (userData) => {
    setLoading(true);
    try {
      await axiosInstance.post('/users/invite', userData);
      toast.success('Invitation successfully!');
      fetchOrgUsers(); // Refresh user list
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error inviting user');
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (id, userData) => {
    setLoading(true);
    try {
      await axiosInstance.patch(`/users/${id}`, userData);
      toast.success('User updated successfully!');
      fetchOrgUsers(); // Refresh user list
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error updating user');
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    setLoading(true);
    try {
      await axiosInstance.delete(`/users/${id}`);
      toast.success('User deleted successfully!');
      fetchOrgUsers(); // Refresh user list
    } catch (error) {
      toast.error('Error deleting user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserContext.Provider
      value={{
        users,
        loading,
        addUser,
        updateUser,
        deleteUser,
        fetchOrgUsers,
        inviteUser,
        fetchUsers,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUsers = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
