import typography from 'assets/theme/base/typography';

const { size } = typography;

const breadCrumbs = {
  styleOverrides: {
    li: {
      lineHeight: 0,
    },

    separator: {
      fontSize: size.sm,
    },
  },
};
export default breadCrumbs;
