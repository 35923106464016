import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import { useUsers } from 'context/UserContext';
import { useOrganizations } from 'context/OrganizationContext';
import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';
import VuiInput from 'components/base/VuiInput';
import VuiSelect from 'components/base/VuiSelect';
import { useNotifications } from 'context/NotificationContext';

function NotificationForm() {
  const { fetchUsers, users } = useUsers();
  const { fetchOrgs, organizations } = useOrganizations();
  const { createNotification } = useNotifications();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [attachedLink, setAttachedLink] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [notificationType, setNotificationType] = useState(''); // New state for selection
  const [allUsers, setAllUsers] = useState(false);

  useEffect(() => {
    fetchUsers();
    fetchOrgs();
  }, [fetchUsers, fetchOrgs]);

  // Submit handler
  const handleSubmit = (e) => {
    e.preventDefault();

    const sanitizedUsers = selectedUsers.map((user) => Number(user));
    const sanitizedOrgs = selectedOrgs.map((org) => Number(org));

    const payload = {
      title,
      content,
      userIds:
        notificationType === 'specificUsers' && sanitizedUsers.length > 0
          ? sanitizedUsers
          : null,
      orgIds:
        notificationType === 'specificOrganizations' && sanitizedOrgs.length > 0
          ? sanitizedOrgs
          : null,
      allUsers: notificationType === 'allUsers',
    };

    if (attachedLink?.trim()) {
      payload.attachedLink = attachedLink.trim();
    }

    createNotification(payload);
  };

  return (
    <Card
      sx={{ padding: 3, flexGrow: 1 }}
      component="form"
      onSubmit={handleSubmit}
    >
      <VuiTypography variant="h5" fontWeight="bold" mb={2} color="white">
        Create Notification
      </VuiTypography>

      {/* Title Input */}
      <VuiBox mb={2}>
        <VuiTypography
          component="label"
          variant="button"
          color="white"
          fontWeight="medium"
        >
          Title
        </VuiTypography>
        <div style={{ minWidth: '100%', borderRadius: '8px', padding: '1px' }}>
          <VuiInput
            type="text"
            placeholder="Enter Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
          />
        </div>
      </VuiBox>

      <VuiBox mb={2}>
        <VuiTypography
          component="label"
          variant="button"
          color="white"
          fontWeight="medium"
        >
          Content
        </VuiTypography>
        <div style={{ minWidth: '100%', borderRadius: '8px', padding: '1px' }}>
          <VuiInput
            type="text"
            placeholder="Enter Content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            fullWidth
          />
        </div>
      </VuiBox>

      <VuiBox mb={2}>
        <VuiTypography
          component="label"
          variant="button"
          color="white"
          fontWeight="medium"
        >
          Attached Link
        </VuiTypography>
        <div style={{ minWidth: '100%', borderRadius: '8px', padding: '1px' }}>
          <VuiInput
            type="text"
            placeholder="Enter Link"
            value={attachedLink}
            onChange={(e) => setAttachedLink(e.target.value)}
            fullWidth
          />
        </div>
      </VuiBox>

      <VuiBox mb={4}>
        <VuiTypography
          component="label"
          variant="button"
          color="white"
          fontWeight="medium"
        >
          Notification Target
        </VuiTypography>
        <VuiSelect
          value={notificationType}
          onChange={(value) => setNotificationType(value)}
          options={[
            { label: 'Notify Specific Users', value: 'specificUsers' },
            {
              label: 'Notify Specific Organizations',
              value: 'specificOrganizations',
            },
            { label: 'Notify All Users', value: 'allUsers' },
          ]}
          placeholder="Select Notification Target"
          menuPortalTarget={true}
        />
      </VuiBox>

      {notificationType === 'specificUsers' && (
        <VuiBox mb={4}>
          <VuiTypography
            component="label"
            variant="button"
            color="white"
            fontWeight="medium"
          >
            Users
          </VuiTypography>
          <VuiSelect
            value={selectedUsers}
            onChange={(values) => setSelectedUsers(values)}
            options={users?.map((user) => ({
              label: user.email,
              value: user.id,
            }))}
            multiple
            placeholder="Select Users"
            menuPortalTarget={true}
          />
        </VuiBox>
      )}

      {notificationType === 'specificOrganizations' && (
        <VuiBox mb={4}>
          <VuiTypography
            component="label"
            variant="button"
            color="white"
            fontWeight="medium"
          >
            Organizations
          </VuiTypography>
          <VuiSelect
            value={selectedOrgs}
            onChange={(values) => setSelectedOrgs(values)}
            options={organizations?.map((org) => ({
              label: `${org.id} - ${org.name}`,
              value: org.id,
            }))}
            multiple
            placeholder="Select Organizations"
            menuPortalTarget={true}
          />
        </VuiBox>
      )}

      <button
        type="submit"
        style={{
          padding: '10px 20px',
          borderRadius: '5px',
          background: '#007bff',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Create Notification
      </button>
    </Card>
  );
}

export default NotificationForm;
