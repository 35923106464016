import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { CircularProgress } from '@mui/material';

import axiosInstance from 'api/axiosInstance';

import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';
import CoverLayout from 'components/layout/CoverLayout';
import bgSignUp from 'assets/images/signUpImage.png';

function EmailVerification() {

  const [isLoading, setIsLoading] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const finishRegistrationCallback = async () => {
    try {
      if (token) {
        const response = await axiosInstance.post('/auth/register/complete', {
          token,
        });
        setIsEmailVerified(true);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Verification failed');
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    finishRegistrationCallback();
  }, []);

  return (
    <CoverLayout
      color="white"
      premotto="Podio"
      motto="Podio Admin DASHBOARD"
      image={bgSignUp}
    >
      <VuiBox>
        {isLoading ? (
          <VuiBox display="flex" justifyContent="center">
            <CircularProgress />
          </VuiBox>
        ) : (
          <VuiTypography color="text" textAlign="center" fontWeight="regular">
            {isEmailVerified ? (
              <>
                Email is verified.<br />
                You can &nbsp;
                <VuiTypography
                  component={Link}
                  to="/authentication/sign-in"
                  color="white"
                  fontWeight="medium"
                >
                  Sign in
                </VuiTypography>

              </>
            ) : (
              <span>Verification failed!</span>
            )}
          </VuiTypography>
        )}
      </VuiBox>
    </CoverLayout>
  );
}

export default EmailVerification;
