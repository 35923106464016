import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import axiosInstance from '../api/axiosInstance';
import toast from 'react-hot-toast';
import { useAuth } from './AuthContext';

const NotificationContext = createContext(undefined);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(5);

  const { isAuthenticated } = useAuth();

  const fetchNotifications = useCallback(
    async (page = 1, limit = 5, append = false) => {
      if (isAuthenticated) {
        setLoading(true);
        try {
          const response = await axiosInstance.get(`/notifications`, {
            params: {page, limit},
          });
          setNotifications((prevNotifications) =>
            append
              ? [...prevNotifications, ...response.data.data]
              : response.data.data
          );
          setUnreadCount(response.data.unreadCount);
          return response.data;
        } catch (error) {
          toast.error('Failed to fetch notifications');
          return null;
        } finally {
          setLoading(false);
        }
      }
    },
    [isAuthenticated]
  );

  const createNotification = async (notificationData) => {
    setLoading(true);
    try {
      await axiosInstance.post(`/notifications`, notificationData);
      toast.success('Notification created successfully');
    } catch (error) {
      toast.error(
        error?.response?.data?.message || 'Failed to create notification'
      );
    } finally {
      setLoading(false);
    }
  };

  // Mark a single notification as read
  const markAsRead = async (notificationId) => {
    setLoading(true);
    try {
      await axiosInstance.patch(`/notifications/${notificationId}/read`);
      toast.success('Notification marked as read');
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, isRead: true }
            : notification
        )
      );
      setUnreadCount(unreadCount - 1);
    } catch (error) {
      toast.error('Failed to mark notification as read');
    } finally {
      setLoading(false);
    }
  };

  // Mark all notifications as read
  const markAllAsRead = async () => {
    setLoading(true);
    try {
      await axiosInstance.patch(`/notifications/read/all`);
      toast.success('All notifications marked as read');
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => ({
          ...notification,
          isRead: true,
        }))
      );
      setUnreadCount(0);
    } catch (error) {
      toast.error('Failed to mark all notifications as read');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchNotifications(1, currentPage * currentLimit);
    }, 60000); // Every 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [fetchNotifications, currentPage, currentLimit]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        loading,
        fetchNotifications,
        createNotification,
        markAsRead,
        markAllAsRead,
        unreadCount,
        setCurrentPage,
        setCurrentLimit,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationProvider'
    );
  }
  return context;
};
