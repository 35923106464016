import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';

import { VisionUIControllerProvider } from 'context';
import { AuthProvider } from 'context/AuthContext';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'assets/theme';

import './global.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <VisionUIControllerProvider>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ThemeProvider>
    </VisionUIControllerProvider>
  </BrowserRouter>
);
