import React from 'react';
import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';
import VuiInput from 'components/base/VuiInput';

const ModalInputField = ({
  label,
  value,
  onChange,
  placeholder,
  type = 'text',
}) => (
  <VuiBox mb={2}>
    <VuiTypography
      component="label"
      variant="button"
      color="white"
      fontWeight="medium"
    >
      {label}
    </VuiTypography>
    <div style={{ minWidth: '100%', borderRadius: '8px', padding: '1px' }}>
      <VuiInput
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        fullWidth
        sx={({ typography: { size } }) => ({
          fontSize: size.sm,
        })}
      />
    </div>
  </VuiBox>
);

export default ModalInputField;
