import React, { createContext, useCallback, useContext, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import toast from 'react-hot-toast';

const OrganizationContext = createContext(undefined);

export const OrganizationProvider = ({ children }) => {
  const [organizations, setOrganizations] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchOrgs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/organizations');
      setOrganizations(response.data);
    } catch (error) {
      toast.error('Error fetching organizations');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <OrganizationContext.Provider
      value={{
        loading,
        fetchOrgs,
        organizations,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganizations = () => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error(
      'useOrganizations must be used within a OrganizationProvider'
    );
  }
  return context;
};
