import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';

import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';
import { HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2';

import DashboardLayout from 'components/layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/layout/Navbars/DashboardNavbar';
import Footer from 'components/layout/Footer';
import Table from 'components/ui/Tables/Table';
import { useUsers } from '../../context/UserContext';
import { useAuth } from '../../context/AuthContext';
import VuiButton from 'components/base/VuiButton';
import UserFormModal from 'components/Modals/UserFormModal';
import InviteUserModal from 'components/Modals/InviteUserModal';
import { ConfirmModal } from 'components/Modals/ConfirmModal';

function Users() {
  const { user } = useAuth();
  const {
    users,
    loading,
    addUser,
    updateUser,
    deleteUser,
    fetchOrgUsers,
    inviteUser,
  } = useUsers();
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [deleteModalProps, setDeleteModalProps] = useState(undefined);

  useEffect(() => {
    fetchOrgUsers();
  }, [fetchOrgUsers]);

  const columns = [
    { name: 'id', align: 'center', label: 'ID' },
    { name: 'name', align: 'center', label: 'Name' },
    { name: 'email', align: 'center', label: 'Email' },
    { name: 'role', align: 'center', label: 'Role' },
    { name: 'status', align: 'center', label: 'Status' },
  ];

  if ((user.userRole === 'super-admin' || user.userRole === 'org-admin')) {
    columns.push({ name: 'actions', align: 'center', label: 'Actions' });
  }

  const rows = users?.map((data, index) => ({
    id: (
      <VuiTypography
        variant="caption"
        justifyContent="center"
        color="white"
        fontWeight="medium"
      >
        {index + 1}
      </VuiTypography>
    ),
    name: (
      <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {data.name}
        </VuiTypography>
      </VuiBox>
    ),
    email: (
      <VuiTypography variant="caption" color="text" fontWeight="medium">
        {data.email}
      </VuiTypography>
    ),
    role: (
      <VuiTypography variant="caption" color="text" fontWeight="medium">
        {data.userRole}
      </VuiTypography>
    ),
    status: (
      <VuiTypography
        variant="caption"
        color={data.status === 'active' ? 'success' : 'text'}
        fontWeight="medium"
      >
        {data.status}
      </VuiTypography>
    ),
    actions: (
      <VuiBox display="flex" justifyContent="center" gap={0}>
        {(user.userRole === 'org-admin' || user.userRole === 'super-admin') && (
          <>
            <IconButton onClick={() => handleEdit(data)}>
              <HiOutlinePencilSquare color="white" size={16} />
            </IconButton>

            {data.id !== user.id && (
              <IconButton onClick={() => handleDelete(data)}>
                <HiOutlineTrash color="white" size={16} />
              </IconButton>
            )}
          </>
        )}
      </VuiBox>
    ),
  }));

  // Handle delete user
  const handleDelete = useCallback(
    (data) => {
      setDeleteModalProps({
        onConfirm: () => {
          deleteUser(data.id).catch(() => {
            toast.error('Failed to delete user.');
          });
        },
        title: 'Delete User',
        prompt: `Are you sure you want to delete user ${data.name} (${data.email})?`,
        importance: 'error',
      });
    },
    [deleteUser]
  );

  // Handle edit user
  const handleEdit = (user) => {
    setUserToEdit(user);
    setEditUserId(user.id);
    setIsFormModalOpen(true);
  };

  // Handle save (create or update)
  const handleSave = async (userData) => {
    if (editUserId) {
      await updateUser(editUserId, userData);
    } else {
      await addUser(userData);
    }
    setIsFormModalOpen(false);
    setEditUserId(null);
    setUserToEdit(null);
  };

  const handleInvite = async (userData) => {
    await inviteUser(userData);
    setIsInviteModalOpen(false);
  };

  const isUserLimitReached =
    user.userLimit !== -1 && users?.length >= user.userLimit;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox
        py={3}
        sx={{
          flex: '1',
        }}
      >
        <VuiBox mb={3}>
          <Card>
            <VuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="22px"
              p={3}
            >
              <VuiTypography variant="lg" color="white">
                Users
              </VuiTypography>
              {(user?.userRole === 'super-admin' ||
                (user?.userRole === 'org-admin' && !isUserLimitReached)) && (
                <VuiButton
                  color="info"
                  onClick={() => setIsInviteModalOpen(true)}
                >
                  Invite User
                </VuiButton>
              )}
            </VuiBox>
            <VuiBox
              sx={{
                '& th': {
                  borderBottom: ({
                    borders: { borderWidth },
                    palette: { grey },
                  }) => `${borderWidth[1]} solid ${grey[700]}`,
                },
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({
                      borders: { borderWidth },
                      palette: { grey },
                    }) => `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} loading={loading} />
            </VuiBox>
          </Card>
        </VuiBox>

        {isInviteModalOpen && (
          <InviteUserModal
            onClose={() => setIsInviteModalOpen(false)}
            onInvite={handleInvite}
          />
        )}
        {isFormModalOpen && (
          <UserFormModal
            onClose={() => {
              setIsFormModalOpen(false);
              setEditUserId(null);
              setUserToEdit(null);
            }}
            onSave={handleSave}
            userToEdit={userToEdit}
          />
        )}
        {deleteModalProps != null && (
          <ConfirmModal
            onClose={() => {
              setDeleteModalProps(undefined);
            }}
            onConfirm={deleteModalProps.onConfirm}
            title={deleteModalProps.title}
            prompt={deleteModalProps.prompt}
            importance={deleteModalProps.importance}
          />
        )}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Users;
