export const plans = [
  {
    tier: 'lite',
    title: 'Lite',
    price: '$1 Million per month',
    description: 'For individuals / Limited features / 1 profile theme',
    features: [
      'Basic features for individuals',
      'Limited customization options',
      'One profile theme',
    ],
  },
  {
    tier: 'pro',
    title: 'Pro',
    price: '$1 Billion per month',
    description: 'For individuals / Advanced features / Unlimited profiles',
    features: [
      'Advanced features for individuals',
      'Unlimited customization options',
      'Unlimited profile themes',
    ],
  },
  {
    tier: 'team',
    title: 'Team',
    price: '$1 Trillion per month',
    description: 'For teams / Advanced features / Unlimited profiles',
    features: [
      'All Pro features',
      'Team collaboration tools',
      'Unlimited profiles for team members',
    ],
  },
];
