import Dashboard from 'pages/dashboard';
import SignIn from 'pages/authentication/sign-in';
import SignUp from 'pages/authentication/sign-up';
import EmailVerification from 'pages/authentication/sign-up/email-verification';

import { IoRocketSharp } from 'react-icons/io5';
import { IoIosDocument } from 'react-icons/io';
import { IoStatsChart } from 'react-icons/io5';
import { IoHome } from 'react-icons/io5';
import Users from 'pages/users';
import ProfileTheme from 'pages/profileTheme';
import Profile from 'pages/profile';
import Notification from 'pages/notification';

const routes = [
  {
    type: 'collapse',
    name: 'Notification',
    key: 'notification',
    route: '/notification',
    icon: <IoHome size="15px" color="inherit" />,
    component: Notification,
    noCollapse: true,
    authRequired: true,
    userRole: ['super-admin'],
  },
  // {
  //   type: 'collapse',
  //   name: 'Dashboard',
  //   key: 'dashboard',
  //   route: '/dashboard',
  //   icon: <IoHome size="15px" color="inherit" />,
  //   component: Dashboard,
  //   noCollapse: true,
  //   authRequired: true,
  //   userRole: ['org-admin', 'normal'],
  // },
  {
    type: 'collapse',
    name: 'Users',
    key: 'users',
    route: '/users',
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: Users,
    noCollapse: true,
    authRequired: true,
    requiredOrgTier: 'team',
    userRole: ['super-admin', 'org-admin', 'normal'],
  },

  {
    type: 'collapse',
    name: 'Themes',
    key: 'profile-theme',
    route: '/profile-theme',
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: ProfileTheme,
    noCollapse: true,
    authRequired: true,
    userRole: ['super-admin', 'org-admin', 'normal'],
  },
  {
    type: 'collapse',
    name: 'Profile',
    key: 'profile',
    route: '/profile',
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: Profile,
    noCollapse: true,
    authRequired: true,
    userRole: ['super-admin', 'org-admin', 'normal'],
  },
  {
    type: 'collapse',
    name: 'Sign In',
    key: 'sign-in',
    route: '/authentication/sign-in',
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Sign Up',
    key: 'sign-up',
    route: '/authentication/sign-up',
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: SignUp,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Finish Registration',
    key: 'finish-registration',
    route: '/authentication/finish-registration',
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: EmailVerification,
    noCollapse: true,
  },
];

export default routes;
