import React from 'react';
import { ChromePicker } from 'react-color';
import VuiTypography from 'components/base/VuiTypography';
import VuiBox from 'components/base/VuiBox';

const ModalColorPicker = ({ label, color, onChange }) => (
  <VuiBox mb={2}>
    <VuiTypography
      component="label"
      variant="button"
      color="white"
      fontWeight="medium"
    >
      {label}
    </VuiTypography>
    <ChromePicker
      color={color}
      onChangeComplete={(color) => onChange(color.hex)}
      disableAlpha
    />
  </VuiBox>
);

export default ModalColorPicker;
