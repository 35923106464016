import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';
import VuiBox from 'components/base/VuiBox';
import Breadcrumbs from 'components/ui/Breadcrumbs';
import NotificationItem from 'components/ui/Items/NotificationItem';
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from 'components/layout/Navbars/DashboardNavbar/styles';
import {
  useVisionUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from 'context';
import { useNotifications } from 'context/NotificationContext';
import { Box, Button, Typography } from '@mui/material';
import VuiTypography from 'components/base/VuiTypography';

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split('/').slice(1);

  // Notifications Context
  const {
    notifications,
    fetchNotifications,
    loading,
    markAllAsRead,
    unreadCount,
    setCurrentPage,
    setCurrentLimit,
  } = useNotifications();
  const [page, setPage] = useState(1);
  const [limit] = useState(5); // Default limit
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    window.addEventListener('scroll', handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    // Fetch the initial notifications
    fetchNotifications(1, limit);
    setCurrentPage(1);
    setCurrentLimit(limit);
  }, [fetchNotifications, limit, setCurrentLimit, setCurrentPage]);

  const loadMoreNotifications = async () => {
    if (!loading && hasMore) {
      const result = await fetchNotifications(page + 1, limit, true); // Fetch the next page
      if (result?.data?.length < limit) {
        setHasMore(false); // Stop loading if less than the limit
      }
      setPage((prevPage) => {
        const newPage = prevPage + 1;
        setCurrentPage(newPage); // Update the context
        return newPage;
      });
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      loadMoreNotifications();
    }
  };

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2, maxHeight: '400px', overflow: 'auto' }}
    >
      <div
        style={{
          maxHeight: 300,
          overflowY: 'auto',
          minWidth: '300px',
        }}
        onScroll={handleScroll}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '8px 16px',
          }}
        >
          <Button
            onClick={markAllAsRead}
            variant="text"
            sx={{
              color: 'inherit',
              textDecoration: 'underline',
              background: 'none',
              padding: 0,
              minWidth: 'auto',
              '&:hover': {
                textDecoration: 'underline',
                background: 'none',
              },
            }}
          >
            Mark All as Read
          </Button>
        </Box>

        {notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            title={notification.title}
            content={notification.content}
            date={notification.createdAt}
            isRead={notification.isRead}
            notification={notification}
          />
        ))}
        {!loading && notifications.length < 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '8px 16px',
            }}
          >
            <VuiTypography
              component="label"
              variant="button"
              color="white"
              fontWeight="medium"
              textAlign="center"
            >
              You currently have no notifications.
            </VuiTypography>
          </Box>
        )}
        {loading && <div>Loading more notifications...</div>}
      </div>
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType ? navbarType : 'sticky'}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
        </VuiBox>
        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <VuiBox color={light ? 'white' : 'inherit'}>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={'text-white'}>
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
                position="absolute"
              >
                <Box marginTop={'5px'}>
                  <Icon className={light ? 'text-white' : 'text-dark'}>
                    notifications
                  </Icon>
                  {unreadCount > 0 && (
                    <Typography
                      variant="button"
                      textTransform="capitalize"
                      fontWeight="regular"
                      position="absolute"
                      color="white !important"
                      fontSize={'10px'}
                      bgcolor="red"
                      width={'15px'}
                      height={'15px'}
                      padding={'2px'}
                      borderRadius="20px"
                      left={'15px'}
                      top={'2px'}
                    >
                      <strong>{unreadCount}</strong>
                    </Typography>
                  )}
                </Box>
              </IconButton>
              {renderMenu()}
            </VuiBox>
          </VuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default DashboardNavbar;
