import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import toast from 'react-hot-toast';
import axiosInstance from 'api/axiosInstance';
import { useAuth } from 'context/AuthContext';
import VuiTypography from 'components/base/VuiTypography';
import PlanCard from './PlanCard';
import { plans } from './plansData';

function Plans() {
  const { user, getProfile, setShowOrgModal } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleUpgradeOrDowngrade = async (newTier) => {
    setLoading(true);
    try {
      await axiosInstance.patch(`/organizations/${user?.organization?.id}`, {
        orgTier: newTier,
      });
      getProfile();
      toast.success(`Successfully changed to ${newTier}!`);
      if (newTier === 'team') {
        setShowOrgModal(true);
      }
    } catch (error) {
      toast.error(`Failed to change to ${newTier}`);
    } finally {
      setLoading(false);
    }
  };

  const currentPlan = user?.organization?.orgTier;

  const getActionType = (current, target) => {
    if (current === target) return 'current';
    if (current === 'lite' && (target === 'pro' || target === 'team'))
      return 'upgrade';
    if (current === 'pro' && target === 'team') return 'upgrade';
    if (current === 'pro' && target === 'lite') return 'downgrade';
    if (current === 'team' && (target === 'lite' || target === 'pro'))
      return 'downgrade';
    return null;
  };

  return (
    <Box sx={{ padding: 4, paddingTop: 1 }}>
      <VuiTypography variant="h4" align="center" gutterBottom color="white">
        Choose Your Plan
      </VuiTypography>
      <Grid container spacing={4} justifyContent="center" marginTop={1}>
        {plans.map((plan) => {
          const actionType = getActionType(currentPlan, plan.tier);
          const isActive = actionType === 'current';
          return (
            <PlanCard
              key={plan.tier}
              plan={plan}
              isActive={isActive}
              actionType={actionType}
              onAction={handleUpgradeOrDowngrade}
              loading={loading}
            />
          );
        })}
      </Grid>
    </Box>
  );
}

export default Plans;
