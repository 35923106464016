import React from 'react';
import { Card } from '@mui/material';
import VuiTypography from 'components/base/VuiTypography';
import Grid from '@mui/material/Grid';
import { useAuth } from 'context/AuthContext';
import Plans from './Plans';
import VuiBox from 'components/base/VuiBox';

function ProfileDetailsCard() {
  const { user } = useAuth();

  return (
    <Card sx={{ padding: 3, flexGrow: 1 }}>
      <VuiBox
        p={'22px'}
        pt={'24px'}
        sx={{
          flex: '1',
        }}
      >
        <VuiTypography variant="lg" color="white">
          Profile Details
        </VuiTypography>

        <Grid container rowSpacing={2} columnSpacing={3} marginTop={1}>
          <Grid item xs={6}>
            <VuiBox>
              <VuiTypography variant="lg" color="white">
                Name
              </VuiTypography>
              <VuiTypography color="text.primary">
                {user?.name || 'N/A'}
              </VuiTypography>
            </VuiBox>
          </Grid>

          <Grid item xs={6}>
            <VuiBox>
              <VuiTypography variant="lg" color="white">
                Email
              </VuiTypography>
              <VuiTypography color="text.secondary">
                {user?.email || 'N/A'}
              </VuiTypography>
            </VuiBox>
          </Grid>

          <Grid item xs={6}>
            <VuiBox>
              <VuiTypography variant="lg" color="white">
                {user.organization?.orgTier === 'team' && 'Organization'} Tier
              </VuiTypography>
              <VuiTypography color="text.secondary">
                {user?.organization?.orgTier || 'N/A'}
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
        {user.userRole === 'org-admin' && <Plans />}
      </VuiBox>
    </Card>
  );
}

export default ProfileDetailsCard;
