import PropTypes from 'prop-types';

import VuiBox from 'components/base/VuiBox';
import VuiTypography from 'components/base/VuiTypography';

import PageLayout from 'components/layout/LayoutContainers/PageLayout';

import colors from 'assets/theme/base/colors';

import tripleLinearGradient from 'assets/theme/functions/tripleLinearGradient';

function CoverLayout({
  color,
  title,
  description,
  motto,
  premotto,
  image,
  top,
  cardContent,
  children,
}) {
  const { gradients } = colors;
  return (
    <PageLayout
      background={tripleLinearGradient(
        gradients.cover.main,
        gradients.cover.state,
        gradients.cover.stateSecondary,
        gradients.cover.angle
      )}
    >
      <VuiBox
        height="100vh"
        display="flex"
      >
        <VuiBox
          height="100%"
          width="50%"
          display={{ xs: 'none', md: 'block' }}
          sx={({ breakpoints }) => ({
            overflow: 'hidden',
            [breakpoints.down('xl')]: {
              mr: '100px',
            },
            [breakpoints.down('lg')]: {
              display: 'none',
            },
          })}
          zIndex={0}
        >
          <VuiBox
            height="100%"
            sx={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <VuiTypography
              textAlign="center"
              variant="subtitle1"
              fontWeight="medium"
              color="white"
              mb="10px"
              sx={{ mb: 1, letterSpacing: '8px' }}
            >
              {premotto}
            </VuiTypography>
            <VuiTypography
              textAlign="center"
              variant="h2"
              fontWeight="bold"
              color="logo"
              mb="10px"
              textGradient
              sx={{ letterSpacing: '8px' }}
            >
              {motto}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <VuiBox
          width={{ xs: '100%', lg: '50%' }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            alignItems: 'center',
            minHeight: '75vh',
            margin: '0 auto',
          }}
        >
          <VuiBox
            mt={top}
            sx={({ breakpoints }) => ({
              [breakpoints.down('xl')]: {
                mr: cardContent ? '50px' : '100px',
              },
              [breakpoints.down('lg')]: {
                mr: 'auto',
                ml: 'auto !important',
              },
              [breakpoints.down('md')]: {
                maxWidth: '90%',
                pr: '7px',
                pl: '10px !important',
              },
            })}
          >
            <VuiBox
              pt={3}
              px={3}
              mx="auto !important"
              maxWidth={cardContent ? '400px' : '350px'}
            >
              <VuiBox mb="35px">
                <VuiTypography
                  textAlign={cardContent ? 'center' : 'start'}
                  variant="h3"
                  fontWeight="bold"
                  color={color}
                  mb="10px"
                >
                  {title}
                </VuiTypography>
                <VuiTypography
                  textAlign={
                    cardContent ? 'center !important' : 'start !important'
                  }
                  mx="auto"
                  sx={({ typography: { size }, functions: { pxToRem } }) => ({
                    fontWeight: 'regular',
                    fontSize: size.sm,
                  })}
                  color="white"
                >
                  {description}
                </VuiTypography>
              </VuiBox>
            </VuiBox>
            <VuiBox
              px={3}
              mb="50px"
              mx="auto"
              ml="auto !important"
              sx={({ breakpoints }) => ({
                mt: cardContent ? '60px' : 0,
                width: cardContent ? '450px' : '350px',
                [breakpoints.down('xl')]: {
                  mr: cardContent ? '0px' : '100px',
                },
                [breakpoints.only('lg')]: {
                  mr: 'auto',
                  ml: 'auto !important',
                },
                [breakpoints.down('lg')]: {
                  mr: 'auto',
                  ml: 'auto !important',
                },
                [breakpoints.down('md')]: {
                  mr: cardContent ? 'auto !important' : 'unset',
                  pr: '7px',
                  pl: cardContent ? '0px !important' : '10px !important',
                },
              })}
            >
              {children}
            </VuiBox>
            {/* <Footer /> */}
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </PageLayout>
  );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  title: '',
  description: '',
  color: 'info',
  top: 10,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
