import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Sidenav from 'components/layout/Sidenav';
import Configurator from 'components/layout/Configurator';
import routes from 'routes';
import { useVisionUIController, setMiniSidenav } from 'context';
import { Toaster } from 'react-hot-toast';
import { useAuth } from './context/AuthContext';
import { UserProvider } from './context/UserContext';
import PrivateRoute from './components/Route/PrivateRoute';
import { ProfileThemeProvider } from 'context/ProfileThemeContext';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import NotFound from 'pages/NotFound';
import { OrganizationProvider } from 'context/OrganizationContext';
import { NotificationProvider } from 'context/NotificationContext';

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { user, profileLoading } = useAuth();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  const accessRoutes = routes.filter((route) => {
    if (!route?.userRole || !user?.userRole) {
      return route;
    }
    return route?.userRole?.find((ur) => ur === user?.userRole);
  });

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) return getRoutes(route.collapse);
      if (route.route) {
        const RouteComponent = route.authRequired ? PrivateRoute : Route;
        return (
          <RouteComponent
            exact
            path={route.route}
            component={route.component}
            key={route.key}
          />
        );
      }
      return null;
    });

  return (
    <OrganizationProvider>
      <UserProvider>
        <NotificationProvider>
          <ProfileThemeProvider>
            <Toaster
              toastOptions={{
                position: 'top-right',
              }}
            />
            <CssBaseline />
            {layout === 'dashboard' && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand=""
                  brandName="Podio Admin"
                  routes={accessRoutes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
              </>
            )}

            <Switch>
              {getRoutes(accessRoutes)}
              <Route exact path="/">
                <Redirect to="/profile" />
              </Route>

              {!profileLoading && <Route component={NotFound} />}
            </Switch>
          </ProfileThemeProvider>
        </NotificationProvider>
      </UserProvider>
    </OrganizationProvider>
  );
}
